@import './common/styles/variables.scss';

html, body, #root {
  height: 100%;
} 

.pl-xs, .ph-xs, .p-xs {
  padding-left: $spasing-xs;
}
.pr-xs, .ph-xs, .p-xs {
  padding-right: $spasing-xs;
}
.pt-xs, .pv-xs, .p-xs {
  padding-top: $spasing-xs;
}
.pb-xs, .pv-xs, .p-xs {
  padding-bottom: $spasing-xs;
}

.pl-s, .ph-s, .p-s {
  padding-left: $spasing-s;
}
.pr-s, .ph-s, .p-s {
  padding-right: $spasing-s;
}
.pt-s, .pv-s, .p-s {
  padding-top: $spasing-s;
}
.pb-s, .pv-s, .p-s {
  padding-bottom: $spasing-s;
}

.pl-m, .ph-m, .p-m {
  padding-left: $spasing-m;
}
.pr-m, .ph-m, .p-m {
  padding-right: $spasing-m;
}
.pt-m, .pv-m, .p-m {
  padding-top: $spasing-m;
}
.pb-m, .pv-m, .p-m {
  padding-bottom: $spasing-m;
}

.pl-l, .ph-l, .p-l {
  padding-left: $spasing-l;
}
.pr-l, .ph-l, .p-l {
  padding-right: $spasing-l;
}
.pt-l, .pv-l, .p-l {
  padding-top: $spasing-l;
}
.pb-l, .pv-l, .p-l {
  padding-bottom: $spasing-l;
}


.ml-xs, .mh-xs, .m-xs {
  margin-left: $spasing-xs;
}
.mr-xs, .mh-xs, .m-xs {
  margin-right: $spasing-xs;
}
.mt-xs, .mv-xs, .m-xs {
  margin-top: $spasing-xs;
}
.mb-xs, .mv-xs, .m-xs {
  margin-bottom: $spasing-xs;
}

.ml-s, .mh-s, .m-s {
  margin-left: $spasing-s;
}
.mr-s, .mh-s, .m-s {
  margin-right: $spasing-s;
}
.mt-s, .mv-s, .m-s {
  margin-top: $spasing-s;
}
.mb-s, .mv-s, .m-s {
  margin-bottom: $spasing-s;
}

.ml-m, .mh-m, .m-m {
  margin-left: $spasing-m;
}
.mr-m, .mh-m, .m-m {
  margin-right: $spasing-m;
}
.mt-m, .mv-m, .m-m {
  margin-top: $spasing-m;
}
.mb-m, .mv-m, .m-m {
  margin-bottom: $spasing-m;
}

.ml-l, .mh-l, .m-l {
  margin-left: $spasing-l;
}
.mr-l, .mh-l, .m-l {
  margin-right: $spasing-l;
}
.mt-l, .mv-l, .m-l {
  margin-top: $spasing-l;
}
.mb-l, .mv-l, .m-l {
  margin-bottom: $spasing-l;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.Shadow--up {
  box-shadow:
    6px 6px 8px #d4d9d6, 
    -6px -6px 8px #f8fffc;
}

.Shadow-small--up {
  box-shadow:
    3px 3px 4px #d4d9d6, 
    -3px -3px 4px #f8fffc;
}

html {
  background-color: $color-light-shades;
}
