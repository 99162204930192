@import '../../common/styles/variables.scss';

.Settings {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}

.SettingsOptionsCard {
  min-width: 300px;
  max-height: 500px;
  .SettingsOptionsCard-Header {
    color: $color-primary;
    font-weight: bold;
    font-size: 1.5rem;

  }
  .SettingsOptionsCard-content {
    display: flex;
    flex-direction: column;
  }
  .SettingsOptionsCard-input {
    flex-grow: 1;
  }
  .SettingsOptionsCard-list--drag {
    padding-bottom: 54px;
  }
}