@import '../../common/styles/variables.scss';

.Users-list {
  display: flex;
  flex-direction: row;
}

.Users-create {
  position: absolute;
  width: 250px;
  top: 50%;
  left: 50%;
  margin-top: -63px;
  margin-left: -125px;
  z-index: 11;
  padding: $spasing-l;
  background-color: $color-light-shades;
  border-radius: $spasing-s;  
  display: flex;
  flex-direction: column;
  color: $color-dark-shades;
  box-shadow: 
    8px 8px 12px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
}

.Users-submitContainer {
  display: flex;
  justify-content: center;
}

.Users-listHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: $color-primary;

  .Users-ListHeaderText {
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .Users-listHeaderButton {
    border-radius: $spasing-s;
    color: $color-primary;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    box-shadow:
      6px 6px 8px #d4d9d6, 
      -6px -6px 8px #f8fffc;
  }
}

.Users-listItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 450px;
  border-radius: $spasing-s;
  color: $color-dark-shades;
  cursor: pointer;
  box-shadow:
    6px 6px 8px #d4d9d6, 
    -6px -6px 8px #f8fffc;

  .Users-listItem--text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
  }

  .Users-listItemButton {
    cursor: pointer;
    border-radius: $spasing-s;
    &:hover {
      background-color: $color-light-shades-hover;
    }
  }
}