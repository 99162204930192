@import '../../styles/variables.scss';

/* Tooltip container */
.Tooltip {
  position: relative;
  display: inline-block;
  // border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.Tooltip .Tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: $color-primary;
  color: $color-light-shades;
  text-align: center;
  padding: 5px 0;
  border-radius: $spasing-s;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;

  top: -5px;
  left: 105%;
  &.left {
    left: -505%;
  }
}

/* Show the tooltip text when you mouse over the tooltip container */
.Tooltip:hover .Tooltip-text {
  visibility: visible;
}