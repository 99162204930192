@import '../../styles/variables.scss';

.NoteBox {
  border-radius: $spasing-s;
  border-width: 1px;
  border-style: solid;
  border-color: $color-warning;
  background-color: $color-warning-transparent;
  padding: $spasing-s;

  .NoteBox-error {
    border-color: $color-danger;
    background-color: $color-danger-transparent;
  }
}