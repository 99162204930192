$spasing-default: 1rem;

$spasing-xs: $spasing-default / 4;
$spasing-s: $spasing-default / 2;
$spasing-m: $spasing-default;
$spasing-l: $spasing-default * 1.5;

/* Colors */
/* 
  http://colormind.io/bootstrap/

  light shades: #E6ECE9   Use this color as the background for your dark-on-light designs, or the text color of an inverted design.
  light accent: #868B92   Accent colors can be used to bring attention to design elements by contrasting with the rest of the palette.
  primary: #5D7C78        This color should be eye-catching but not harsh. It can be liberally applied to your layout as its main identity.
  dark accent: #7B7272    Another accent color to consider. Not all colors have to be used - sometimes a simple color scheme works best.
  dark shades: #232C33    Use as the text color for dark-on-light designs, or as the background for inverted designs.

  primary:  #60807a
  info:     #232c33
  success:  #52a15d
  warning:  #cf9125
  dange:    #f44336

*/
$color-light-shades: #E6ECE9;
$color-light-shades-hover: #d5dad8;
$color-light-accent: #868B92;
$color-primary: #5D7C78;
$color-dark-accent: #7B7272;
$color-dark-shades: #232C33;

$color-info: $color-dark-shades;
$color-success: #52a15d;
$color-warning: #cf9125;
$color-warning-transparent: #cf912520;
$color-danger: #f44336;
$color-danger-transparent: #f4433620;
