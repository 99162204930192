@import '../styles/variables.scss';

.StarsSelect {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $color-dark-shades;
  background-color: $color-light-shades;
  background-clip: padding-box;
  border: 0px;
  border-radius: $spasing-s;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  box-sizing: border-box;
  box-shadow: inset 3px 3px 5px 0 rgba(0, 0, 0, 0.2),
    inset -3px -3px 5px 0 rgba(255, 255, 255, 0.5);

  .StarsSelect-item {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    color: $color-warning;
    border-radius: $spasing-s;
    cursor: pointer;
    &:hover {
      box-shadow:
        1px 1px 2px #d4d9d6, 
        -1px -1px 2px #f8fffc;
    }

    &.StarsSelect-item--selected {
      box-shadow:
        3px 3px 4px #d4d9d6, 
        -3px -3px 4px #f8fffc;
    }
  }
}