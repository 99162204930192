@import '../../common/styles/variables.scss';

.MonthlyReport-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .highlighted {
    color: #5D7C78;
    font-weight: bold;
  }

  .MonthlyReport-summaryHeader {
    color: #5D7C78;
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
  }

  .MonthlyReport-summaryContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .MonthlyReport-summaryControls {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .MonthlyReport-summaryMonth {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    font-weight: bold;
    padding-bottom: .2rem;
    color: #5D7C78;
    min-width: 100px;
    justify-content: center;
  }

  .MonthlyReport-summaryButton {
    color: #5D7C78;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: $spasing-xs;

    &:hover {
      background-color: $color-light-shades-hover;
    }

    &.rotate {
      transform: rotate(180deg);
    }
  }
}

.MonthlyReport-roomsRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .MonthlyReport-roomsInput {
    width: 80px;
  }
}

.MonthlyReport-calendarContainer {
  width: 900px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;

  .MonthlyReport-calendarDay {
    width: 88px;
    .FormInput {
      margin-bottom: $spasing-s;
      &:last-child {
        margin-bottom: -$spasing-xs;
      }
    }
    .highlighted {
      text-align: end;
    }
  }

  .MonthlyReport-calendarDayEmpty {
    width: 120px;
  }
}