@import '../../styles/variables.scss';

.NavBar {
  width: 180px;
  background-color: transparent;
  color: $color-light-shades;
  display: flex;
  flex-direction: column;
}

.NavBarHeader {
  background-color: $color-primary;
  height: 100px;
  // border-top-right-radius: $spasing-m;
  &.NavBarHeader-roundCorner {
    border-bottom-right-radius: $spasing-m;
  }
}

.NavBarItem {
  background-color: $color-primary;

  .NavBarItem-link {
    text-decoration: none;
    color: $color-light-shades;
    font-weight: bold;
  }
  .NavBarItem-container {
    box-sizing: border-box;
  }
  .NavBarItem-before, .NavBarItem-after {
    background-color: $color-primary;
    height: $spasing-s;
  }
  .NavBarItem-sides {
    background-color: $color-light-shades;
  }

  &.NavBarItem-selected {
    .NavBarItem-link {
      color: $color-primary;
    }
    .NavBarItem-before {
      border-bottom-right-radius: $spasing-s;
    }
    .NavBarItem-after {
      border-top-right-radius: $spasing-s;
    }
    .NavBarItem-container {
      background-color: $color-light-shades;
      color: $color-primary;
      border-top-left-radius: $spasing-m;
      border-bottom-left-radius: $spasing-m;
    }
  }
}

.NavBarFooter {
  flex-grow: 2;
  background-color: $color-primary;
  // border-bottom-right-radius: $spasing-m;
}