@import '../../styles/variables.scss';

.ProfileMenu {
  position: absolute;
  width: 250px;
  top: $spasing-l + 3rem;
  right: $spasing-l;
  background-color: $color-light-shades;
  border-radius: $spasing-s;  
  display: flex;
  flex-direction: column;
  color: $color-dark-shades;
  box-shadow: 
    8px 8px 12px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
  z-index: 100;

  .Profile-header {
    padding: $spasing-l;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
  }

  .Profile-menuSection {
    border: 1px solid $color-light-accent;
    border-right-width: 0;
    border-left-width: 0;
    padding-top: $spasing-xs;
    padding-bottom: $spasing-xs;
  }
  .Profile-menuItem {
    cursor: pointer;
    padding: $spasing-m;
    padding-top: $spasing-s;
    padding-bottom: $spasing-s;

    &:hover {
      background-color: $color-light-accent;
    }
  }
  .ProfileMenu-footer {
    padding-top: $spasing-xs;
    padding-bottom: $spasing-m;
  }
};
