@import '../../common/styles/variables.scss';

.Login-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.Login-input {
  width: 300px;
}

.Login-container {
  display: flex;
  flex-direction: column;
  width: 200px;
  border: solid 1px #000;
  border-radius: 1rem;
}

.Login-submitContainert {
  display: flex;
  justify-content: center;
}

.Login-forgotPassword {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: $color-primary;
}

.Login-badLogin {
  color: $color-danger;
  margin-top: -$spasing-m;
}

.Login-resetSent {
  border-radius: $spasing-m;
  border: 1px solid $color-primary;
  background-color: $color-light-shades-hover;
  width: 325px;
  text-align: justify;
}