@import '../../common/styles/variables.scss';

.DataContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: $spasing-xs;
  border-radius: $spasing-s;

  .DataContainer-column {
    width: 80px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 $spasing-xs;
  }

  .DataContainer-header {
    display: flex;
    height: 30px;
    justify-content: center;
    align-items: center;
    background-color: $color-primary;
    color: $color-light-shades;
    border-top-left-radius: $spasing-s;
    border-top-right-radius: $spasing-s;
    border-bottom: 1px solid $color-light-shades;
  }

  .DataContainer-headerInput {
    background-color: $color-primary;
    color: $color-light-shades;
    border: 0;
    width: 30px;
    text-align: right; 
  }

  .DataContainer-subHeader {
    display: flex;
    flex-direction: row;
    background-color: $color-primary;
    color: $color-light-shades;
    margin-bottom: $spasing-xs;

    .DataContainer-column {
      // border-right: 1px solid $color-light-shades;
      justify-content: center;

      &:last-child {
        border-right: 0;
      }
    }
    .left {
      width: 100px;
    }
  }

  .DataContainer-content {
    display: flex;
    flex-direction: row;
  }

  .DataContainer-left {
    display: flex;
    flex-direction: column;
    // border-right: 1px solid $color-primary;
    .DataContainer-column {
      width: 100px;

    }
  }

  .DataContainer-input {
    height: 28px;
    padding: 0.1rem .3rem;
    text-align: right;
    border-radius: 0.2rem;
  }
}
