@import '../../styles/variables.scss';

.Select {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  &.Select--bordered {
    height: calc(1.5em + .75rem + 2px);
    width: 100%;
    border-radius: $spasing-s;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    box-sizing: border-box;
    box-shadow: inset 3px 3px 5px 0 rgba(0, 0, 0, 0.2),
      inset -3px -3px 5px 0 rgba(255, 255, 255, 0.5);
  
    &:hover {
      outline: 0;
      box-shadow: inset 3px 3px 5px 0 rgba(93, 124, 120, 0.7),
      inset -3px -3px 5px 0 rgba(255, 255, 255, 0.5);
    }
  }
  color: $color-dark-shades;
  background-color: $color-light-shades;
  background-clip: padding-box;
  border: 0px;
  

  .Select-text {
    flex-grow: 1;
  }

}

.Select-options {
  position: absolute;
  display: flex;
  flex-direction: column;
  max-height: 300px;
  z-index: 11;
  overflow-y: scroll;
  background-color: $color-light-shades;
  border-radius: $spasing-s;
  
  .Select-optionsItem {
    cursor: pointer;
    padding: $spasing-s .75rem;
    border-bottom: 1px solid $color-dark-accent;

    &:last-child {
      border-bottom-width: 0;
    }

    &:hover, &.Select-optionsItem--selected {
      background-color: $color-light-shades-hover;
    }
  }
}