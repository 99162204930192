@import '../../common/styles/variables.scss';

.HotelsList-column {
    flex-grow: 1;
    flex-basis: 200px;

    &:not(:first-child) {
        margin-left: $spasing-m;
    }
}

.HotelsList-list {
    display: flex;
    flex-direction: column;

    &.MultiSelect {
        max-height: calc(100vh - 120px);
    }
}

.HotelItem {
    display: flex;
    flex-direction: row;
}

.HotelItem-meta {
    display: flex;
    flex-direction: row;
    text-transform: capitalize;
}

.HotelsItem-stars {
    display: flex;
    justify-content: center;
    color: $color-warning;
    border-radius: $spasing-s;
    cursor: pointer;
}

.HotelDetails-info {
    width: 125px;
}