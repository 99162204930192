@import '../../styles/variables.scss';

.ContentHeader {
  display: flex;
  flex-direction: row;
  align-items: center;

  .ContentHeader-logoContainer {
    display: flex;
    align-items: center;
  }
  .ContentHeader-logo {
    height: 40px;
  }
  .ContentHeader-middle {
    flex-grow: 2;
  }
}

.HeaderControls{
  .HeaderControls-avatar {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background-color: $color-primary;
    color: $color-light-shades;
    font-weight: bold;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 
      4px 4px 6px 0 rgba(0, 0, 0, 0.25),
      -4px -4px 6px 0 rgba(255, 255, 255, 0.3);
  }
}