.ContentContainer {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  .ContentContainer-mainPart {
    flex-grow: 2;
  }

}