@import '../../styles/variables.scss';

.Card {
  border-radius: $spasing-s;
}

.Card--up {
  box-shadow:
    6px 6px 8px #d4d9d6, 
    -6px -6px 8px #f8fffc;
}

.Card--down {
  box-shadow:
    inset 6px 6px 8px #d4d9d6, 
    inset -6px -6px 8px #f8fffc;
}