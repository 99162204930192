@import '../../styles/variables.scss';

.Button {
  display: inline-block;
  font-weight: 400;
  color: $color-light-shades;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: $color-light-accent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: $spasing-s;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  &:not(:disabled) {
    cursor: pointer;
  }
  &.Button-primary {
    background-color: $color-primary;
  }
  &.Button-info {
    background-color: $color-info;
  }
  &.Button-success {
    background-color: $color-success;
  }
  &.Button-warning {
    background-color: $color-warning;
  }
  &.Button-danger {
    background-color: $color-danger;
  }
}
