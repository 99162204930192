@import '../../common/styles/variables.scss';

.DataRegistration {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.DataRegistration-forms {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.WeekRegistration-header {
  color: $color-primary;
  font-weight: bold;
  font-size: 1.3rem;
  text-align: center;
  margin-bottom: $spasing-xs;
}

.WeekRegistration-headerButton {
  cursor: pointer;
}

.WeekRegistration {
  justify-content: space-between;
}

.WeekRegistration-arrowColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.WeekRegistration-arrow {
  cursor: pointer;
  // TODO: indicate onClick event
}

.Week-columnItem {
  font-size: .85rem;
  text-align: center;
  width: 26px;
}

.Week-higlited {
  background-color: $color-primary;
  color: $color-light-shades;
  border-radius: 0.5rem;
}