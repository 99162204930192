@import '../../styles/variables.scss';

.Checkbox {
  isolation: isolate;
  position: relative;
  height: 30px;
  width: 60px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow:
    -8px -4px 8px 0px #f8fffc,
    8px 4px 12px 0px #d4d9d6,
    4px 4px 4px 0px #d4d9d6 inset,
    -4px -4px 4px 0px #f8fffc inset;
}

.Checkbox-checked {
  background-color: $color-primary;
  box-shadow:
    -8px -4px 8px 0px #f8fffc,
    8px 4px 12px 0px #d4d9d6,
    4px 4px 4px 0px #577571 inset,
    -4px -4px 4px 0px #63837f inset;

  .Checkbox-indicator {
    box-shadow:
      -8px -4px 8px 0px #577571,
      8px 4px 12px 0px #d1d9e6;
  }
}

.Checkbox-state {
  display: none;
}

.Checkbox-indicator {
  height: 100%;
  width: 200%;
  background: #ecf0f3;
  border-radius: 15px;
  transform: translate3d(-75%, 0, 0);
  transition: transform 0.4s cubic-bezier(0.85, 0.05, 0.18, 1.35);
  box-shadow:
    -8px -4px 8px 0px #ffffff,
    8px 4px 12px 0px #d1d9e6;
}

.Checkbox-state:checked ~ .Checkbox-indicator {
  transform: translate3d(25%, 0, 0);
}