@import '../../common/styles/variables.scss';

.Hotel {
  max-width: 400px;
}

.Hotel-formHeader {
  color: $color-primary;
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: $spasing-m;
}