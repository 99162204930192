@import '../../styles/variables.scss';

.MultiSelect {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  max-height: 120px;
  overflow-y: scroll;
  // height: calc(1.5em + .75rem + 2px);
  // padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $color-dark-shades;
  background-color: $color-light-shades;
  background-clip: padding-box;
  border: 0px;
  border-radius: $spasing-s;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  box-sizing: border-box;
  box-shadow: inset 3px 3px 5px 0 rgba(0, 0, 0, 0.2),
    inset -3px -3px 5px 0 rgba(255, 255, 255, 0.5);

  .MultiSelect-option { // TODO: fix styles
    // height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    border-bottom: 1px solid $color-light-shades-hover;
    cursor: pointer;

    &:hover, &.MultiSelect-option--selected {
      background-color: $color-light-shades-hover;
    }

    &:last-child {
      border-bottom-width: 0;
    }

    .MultiSelect-optionCheckmark {
      color: $color-primary;
    }
  }
}