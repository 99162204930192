@import '../../common/styles/variables.scss';

.Dashboard-Header {
  color: $color-primary;
  font-weight: bold;
  font-size: 1.5rem;
}

.Dashboard-listHeader {
  color: $color-primary;
  font-weight: bold;
  &.filterName {
    flex-grow: 1;
  }
}

.Dashboard-listCell {
  flex-grow: 1;
  flex-basis: 0;
}

.Dashboard-listLineName {
  flex-grow: 1;
  text-overflow: ellipsis;
}

.Dashboard-listTrash {
  border-radius: $spasing-s;
  cursor: pointer;
  &:hover {
    background-color: $color-light-shades-hover;
  }
}

.Dashboard-ListNumberColumn {
  text-align: right;
  width: 120px;
  &.Dashboard-ListNumberColumn--small {
    width: 100px
  }
}

.Dashboard-chartsContainer {
  justify-content: space-between;
}

.Dashboard-chartHeader {
  text-align: center;
  color: $color-primary;
  font-weight: bold;
  font-size: 1.2rem;
}

.Dashboard-addFilter {
  cursor: pointer;

  &:hover {
    background-color: $color-light-shades-hover;
  }
}

.DashboardAddFilter {
  position: absolute;
  width: 600px;
  left: 50%;
  top: 100px;
  margin-left: -300px;
  z-index: 11;
  padding: $spasing-l;
  background-color: $color-light-shades;
  border-radius: $spasing-s;  
  display: flex;
  flex-direction: column;
  color: $color-dark-shades;
  box-shadow: 
    8px 8px 12px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.3);

  .DashboardAddFilter-header {
    color: $color-primary;
    font-weight: bold;
    font-size: 1.7rem;
    margin-bottom: $spasing-m;
  }

  .DashboardAddFilter-form {
    width: 300px
  }
  
  .DashboardAddFilter-hotelsList {
    width: 285px;
    .MultiSelect {
      max-height: 400px;
    }
  }
}

.Dashboard-exportButton {
  box-sizing: border-box;
  box-shadow: inset 3px 3px 5px 0 rgba(0, 0, 0, 0.2),
    inset -3px -3px 5px 0 rgba(255, 255, 255, 0.5);

  &:hover {
    outline: 0;
    box-shadow: inset 3px 3px 5px 0 rgba(93, 124, 120, 0.7),
    inset -3px -3px 5px 0 rgba(255, 255, 255, 0.5);
  }
  color: $color-dark-shades;
  background-color: $color-light-shades;
}

.Dasboard-noReportWarning {
  color: $color-danger;
  font-weight: bold;
}

.Dashboard-link {
  color: $color-primary;
  font-weight: bold;
  text-decoration: none;
}