@import '../../common/styles/variables.scss';

.Events-dayHeader {
  color: $color-primary;
  font-weight: bold;
  font-size: 1.3rem;
}

.Events-addButton {
  cursor: pointer;
}

.Events-form {
  width: 250px;
}
