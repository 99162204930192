@import '../../styles/variables.scss';

.Avatar {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  font-size: 1.3rem;
  background-color: $color-primary;
  color: $color-light-shades;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  &.Avatar-small {
    height: 24px;
    width: 24px;
    border-radius: 12px;
    font-size: 1rem;
  }
  &.Avatar-large {
    height: 60px;
    width: 60px;
    border-radius: 30px;
    font-size: 1.6rem;
  }
  &.Avatar-clickable {
    cursor: pointer;
  }
  &.Avatar-withShadow {
    box-shadow: 
      4px 4px 6px 0 rgba(0, 0, 0, 0.25),
      -4px -4px 6px 0 rgba(255, 255, 255, 0.3);
  }
}